import React, { useState, useEffect } from 'react'
import HomePage from './index'
import axios from 'axios'
import { useLangContext } from '../context/lang.context'
import queryString from 'query-string'
import { useLocation } from '@reach/router'
import Popup from '../components/Popup'
import Seo from '../components/Seo'
import Button from '../components/Button'
import Spinner from '../components/Spinner'
import { sResponse } from '../components/FormFeedback'
import { colors, s } from '../style'

const messages = [
  {
    title: {
      pl: 'Newsletter',
      en: 'Newsletter',
    },
    desc: {
      pl: 'Trwa weryfikacja Twojego adresu w bazie subskrybentów. Proces może potrwać kilka sekund...',
      en: 'Your address is being verified in the subscriber base. The process may take a few seconds ...',
    },
  },
  {
    title: {
      pl: 'Dziękujemy!',
      en: 'Thank you!',
    },
    desc: {
      pl: 'Świetnie! Pomyślnie dodano Twój adres do listy subskrybentów. Niebawem najnowsze informacje pojawią się na Twojej skrzynce pocztowej!',
      en: 'Excellent! Your address has been successfully added to the list of subscribers. Soon the latest posts will appear in your mailbox!',
    },
  },
  {
    title: {
      pl: 'Subskrybujesz!',
      en: 'Subscribing!',
    },
    desc: {
      pl: 'Twój adres email znajduje się na liście subskrybentów newslettera Omida. Niebawem najnowsze informacje pojawią się na Twojej skrzynce pocztowej!',
      en: 'Your email address already exists on the list of subscribers to the Omida newsletter. Soon the latest information will appear in your mailbox!',
    },
  },
  {
    title: {
      pl: 'Niepowodzenie',
      en: 'Failure',
    },
    desc: {
      pl: 'Wystąpił błąd podczas dodawania adresu do listy subskrybentów. Prosimy spróbować ponownie za jakiś czas. Wiadomość z serwera: ',
      en: 'An error occurred while adding an address to the subscriber list. Please try again later. Server message: ',
    },
  },
  {
    title: {
      pl: 'Błędne dane',
      en: 'Invalid data',
    },
    desc: {
      pl: 'Wygląda na to, że podane dane są niepoprawne. Prosimy spróbować ponownie za jakiś czas. Wiadomość z serwera: ',
      en: 'Wygląda na to, że podane dane są niepoprawne. Prosimy spróbować ponownie za jakiś czas. Wiadomość z serwera: ',
    },
  },
  {
    title: {
      pl: 'Nieodnaleziono',
      en: 'Not found',
    },
    desc: {
      pl: 'Twój adres email nie znajduje się na liście subskrybentów newslettera Omida. Wiadomość z serwera: ',
      en: 'Your email address is not on the list of subscribers to the Omida newsletter. Server message: ',
    },
  },
]

const Newsletter = () => {
  const { lang } = useLangContext()
  const location = useLocation()
  // const path = location.pathname
  const params = queryString.parse(location.search)
  const token = params.token
  const email = params.email
  const campaignToken = params.campaignToken

  const [open, setOpen] = useState(true)
  const [code, setCode] = useState(0)
  const [serverMessage, setServerMessage] = useState('')

  /* es-lint disable */
  useEffect(() => {
    if (!token || !email || !campaignToken) return setOpen(false)
    axios({
      method: 'POST',
      url:
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:5000/seaandair/newsletter/confirm'
          : 'https://cleverserver.appclever.pl/seaandair/newsletter/confirm',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: {
        email,
        token,
        campaignToken,
      },
    })
      .then((res) => {
        console.log(res)
        setServerMessage(res.data.message)
        setCode(res.data.code)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [email, token, campaignToken])
  /* es-lint enable */

  return (
    <>
      <Seo>
        <meta name='robots' content={'noindex, nofollow'} />
      </Seo>
      <HomePage />
      <Popup center open={open} setOpen={setOpen}>
        <div
          css={[
            sResponse,
            {
              color: colors.brandDarker,
              '.response-text': {
                [s.md]: {
                  maxWidth: '100%',
                },
              },
            },
          ]}>
          <h1 className='response-title'>{messages[code].title[lang]}</h1>
          <p className='response-text'>
            {messages[code].desc[lang] + (code > 2 ? serverMessage : '')}
          </p>
          <Button
            noMargin
            reactiveBlack
            action={() => setOpen(false)}
            active={code > 0}
            extraCss={sButton}>
            {code === 0 && (
              <>
                <Spinner extraCss={sSpinner} />
              </>
            )}
            {code > 0 && 'OK'}
          </Button>
        </div>
      </Popup>
    </>
  )
}

const sSpinner = {
  transform: 'scale(0.33)',
}

const sButton = {
  minHeight: 48,
  minWidth: 144,
  maxHeight: 48,
  marginTop: '2.5rem',
  position: 'relative',
}

export default Newsletter
